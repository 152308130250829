import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import BlogLayout from "../../components/BlogLayout"

const BlogContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
`

const BlogHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`

const BlogTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
`

const BlogSubtitle = styled.p`
  color: #666;
  max-width: 600px;
  margin: 0 auto;
`

const BlogGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const BlogCard = styled.article`
  background: white;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
  
  .gatsby-image {
    width: 100%;
    height: 220px;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  &:hover .gatsby-image {
    transform: scale(1.05);
  }
`

const CardContent = styled.div`
  padding: 1.5rem;
`

const PostDate = styled.p`
  color: #777;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
`

const PostTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: #3b82f6;
    }
  }
`

const ReadMoreLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  margin-top: 0.75rem;
  color: #3b82f6;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease;
  
  &:hover {
    color: #2563eb;
  }
  
  svg {
    width: 12px;
    height: 12px;
    margin-left: 4px;
  }
`

const BlogPage = ({ data, location }) => {
  const posts = data.allSanityPost.nodes

  return (
    <BlogLayout
      title="Our Blog"
      description="Discover the latest insights, tips, and updates from our team"
      pathname={location.pathname}
    >
      <BlogContainer>
        <BlogHeader>
          <BlogTitle>Our Blog</BlogTitle>
          <BlogSubtitle>Discover the latest insights, tips, and updates from our team</BlogSubtitle>
        </BlogHeader>
        <BlogGrid>
          {posts.map(post => (
            <BlogCard key={post.slug.current}>
              {post.image && post.image.asset && (
                <ImageContainer>
                  <GatsbyImage 
                    image={getImage(post.image.asset)} 
                    alt={post.title} 
                    className="gatsby-image"
                  />
                </ImageContainer>
              )}
              <CardContent>
                <PostDate>
                  {new Date(post.publishedAt || post._createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </PostDate>
                <PostTitle>
                  <Link to={`/blog/${post.slug.current}`}>
                    {post.title}
                  </Link>
                </PostTitle>
                <ReadMoreLink to={`/blog/${post.slug.current}`}>
                  Read more
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </ReadMoreLink>
              </CardContent>
            </BlogCard>
          ))}
        </BlogGrid>
      </BlogContainer>
    </BlogLayout>
  )
}

export const query = graphql`
  query BlogPostsQuery {
    allSanityPost {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        _createdAt
        image {
          asset {
            gatsbyImageData(width: 600, height: 400, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default BlogPage